import React from 'react';
import NavigationContainer from "../NavigationContainer";
import ContentHeader from "../Typo/ContentHeader";
import {useMutation, useQuery} from "@apollo/client";
import ClientAccessRequestQuery from "../../graphql/queries/clientAccessRequest.graphql";
import AcceptClientAccessRequestMutation from "../../graphql/mutations/acceptClientAccessRequest.graphql";
import RejectClientAccessRequestMutation from "../../graphql/mutations/rejectClientAccessRequest.graphql";
import _ from "lodash";
import Loader from "../Loader";
import Panel from "../Panel";
import {useTranslation} from "react-i18next";
import {Col, message, Row} from "antd";
import ClientAccessRequestDetailsContent from "./details";
import ClientAccessRequestActions from "./actions";

interface Props {
    id: string | number
}

const ClientAccessRequestDashboard = ({id}: Props) => {

    const {t} = useTranslation();

    const clientAccessRequestQuery = useQuery(ClientAccessRequestQuery, {
        variables: {
            id: id
        }
    });

    const [acceptClientAccessRequest] = useMutation(AcceptClientAccessRequestMutation, {
        refetchQueries: ["clientAccessRequestList"]
    });

    const [rejectClientAccessRequest] = useMutation(RejectClientAccessRequestMutation, {
        refetchQueries: ["clientAccessRequestList"]
    });

    const _handleAccept = (comment) => {
        return acceptClientAccessRequest({
            variables: {
                id: id,
                input: {
                    respondComment: comment
                }
            }
        })
        .then((res) => {
            message.success(_.get(res, 'data.acceptClientAccessRequest.message'));
        })
        .catch((err) => {
            message.error("Fehler beim Bearbeiten der Anfrage.");
        })
    }

    const _handleReject = (comment) => {
        return rejectClientAccessRequest({
            variables: {
                id: id,
                input: {
                    respondComment: comment
                }
            }
        })
        .then((res) => {
            message.success(_.get(res, 'data.message'));
        })
        .catch((err) => {
            message.error(err);
        })
    }

    if (clientAccessRequestQuery.loading) {
        return <Loader/>
    }

    const accessRequest = _.get(clientAccessRequestQuery, 'data.clientAccessRequest')
    const requestedByName = _.get(accessRequest, 'requestedBy.name')
    const clientName = _.get(accessRequest, 'client.name')

    return <NavigationContainer
        navigationBar={
            <div>
                <ContentHeader title={"Zugriff von " + requestedByName + " auf " + clientName} />
            </div>
        }
    >
        <div className={"mh-15"}>
            <Row gutter={16}>
                <Col md={12}>
                    <Panel title={t("Details")}>
                        <ClientAccessRequestDetailsContent clientAccessRequest={accessRequest}/>
                    </Panel>
                </Col>

                <Col md={12}>
                    <Panel title={t("Actions")}>
                        <ClientAccessRequestActions
                            onAccept={(comment) => _handleAccept(comment)}
                            onReject={(comment) => _handleReject(comment)}
                            clientAccessRequest={accessRequest}
                        />
                    </Panel>
                </Col>
            </Row>
        </div>
    </NavigationContainer>



}

export default ClientAccessRequestDashboard;
