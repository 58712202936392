// @flow
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {graphql} from '@apollo/client/react/hoc';
import {compose} from "recompose";
import IconSplitPanelFilesQuery from "../../graphql/queries/iconSplitPanelFiles.graphql";
import _ from "lodash";
import SplitPanelList from "../SplitPanelList";
import waitWhileLoading from "../../hoc/waitWhileLoading";


type Props = {
    t: Function,
    componentId: string,
    iconSplitPanelFilesQuery: any
}


class IconSplitPanelFiles extends Component<Props> {

    render() {

        const {t, componentId, iconSplitPanelFilesQuery} = this.props;
        const totalFiles = _.get(iconSplitPanelFilesQuery, 'component.componentFileList.total');

        return <SplitPanelList
            dataCy={'TEST_30000'}
            items={[
                {
                    link: `/infrastructure/${componentId}/files`,
                    largeTitle: totalFiles,
                    title: t('Document', {count: totalFiles}),
                    description: t('Show or upload QR code and files'),
                    icon: "copy",
                }
            ]}
        />


    }

}


export default compose(
    withTranslation(),
    graphql(IconSplitPanelFilesQuery, {
        name: 'iconSplitPanelFilesQuery',
        options: (props: Props) => ({
            variables: {
                componentId: props.componentId
            }
        })
    }),
    waitWhileLoading('iconSplitPanelFilesQuery', 'component')
)(IconSplitPanelFiles);

