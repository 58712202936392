import React, {Component} from 'react';
import _ from "lodash";
import {
    mdiAccount,
    mdiAccountBoxMultiple,
    mdiAccountCheck,
    mdiAccountOutline,
    mdiBell,
    mdiBox,
    mdiCalendarToday,
    mdiCashUsdOutline,
    mdiChartBox,
    mdiCheckboxBlankOutline,
    mdiCheckboxMarked,
    mdiCodeTags,
    mdiCog,
    mdiCogOutline,
    mdiCogs,
    mdiCube,
    mdiDelete,
    mdiDomain,
    mdiDownload,
    mdiEarth,
    mdiFaceAgent,
    mdiFile,
    mdiFileExcel,
    mdiFileImage,
    mdiFilePdf,
    mdiFileVideo,
    mdiFileWord,
    mdiFinance,
    mdiFlashAuto,
    mdiFolderAccountOutline,
    mdiForklift,
    mdiHeadQuestionOutline,
    mdiLink,
    mdiLinkOff,
    mdiLock,
    mdiMagnify,
    mdiMenu,
    mdiMenuDown,
    mdiMessageProcessing,
    mdiNewspaper,
    mdiPackageVariantClosed,
    mdiPaperclip,
    mdiPlaylistEdit,
    mdiShareVariant,
    mdiSpeedometer,
    mdiStore,
    mdiTag,
    mdiTruck,
    mdiWrench,
    mdiZipBox,
    mdiOpenInNew,
    mdiAccountArrowRight,
    mdiChevronRight,
    mdiApps,
    mdiSignalCellular1,
    mdiSignalCellular2,
    mdiSignalCellular3,
    mdiSignalOff
} from "@mdi/js";
import Icon from '@mdi/react';


type Props = {
    name: string,
    circled?: boolean,
    size?: number | string,
};

const MDIMap = {
    'open-in-new' :mdiOpenInNew,
    "forklift": mdiForklift,
    "speedometer": mdiSpeedometer,
    "magnify": mdiMagnify,
    "checkbox-marked": mdiCheckboxMarked,
    "checkbox-blank-outline": mdiCheckboxBlankOutline,
    "download": mdiDownload,
    "delete": mdiDelete,
    "tag": mdiTag,
    "share-variant": mdiShareVariant,
    "lock": mdiLock,
    "file": mdiFile,
    "file-image": mdiFileImage,
    "file-video": mdiFileVideo,
    "file-pdf": mdiFilePdf,
    "file-excel": mdiFileExcel,
    "file-word": mdiFileWord,
    "code-tags": mdiCodeTags,
    "zip-box": mdiZipBox,
    "bell": mdiBell,
    "paperclip": mdiPaperclip,
    "account": mdiAccount,
    "settings-outline": mdiCogOutline,
    "face-agent": mdiFaceAgent,
    "earth": mdiEarth,
    "domain": mdiDomain,
    "newspaper": mdiNewspaper,
    "truck": mdiTruck,
    "cash-usd-outline": mdiCashUsdOutline,
    "folder-account-outline": mdiFolderAccountOutline,
    "playlist-edit": mdiPlaylistEdit,
    "flash-auto": mdiFlashAuto,
    "cubes": mdiCogs,
    "user": mdiAccount,
    "users": mdiAccountBoxMultiple,
    "box": mdiBox,
    "package": mdiPackageVariantClosed,
    "cube": mdiCube,
    "wrench": mdiWrench,
    "account-outline": mdiAccountOutline,
    "account-check": mdiAccountCheck,
    "caret-down": mdiMenuDown,
    "link": mdiLink,
    "linkOff": mdiLinkOff,
    "unlink": mdiLinkOff,
    "menu": mdiMenu,
    "store": mdiStore,
    "building": mdiStore,
    "calendarToday": mdiCalendarToday,
    "cog": mdiCog,
    "message-processing": mdiMessageProcessing,
    "finance": mdiFinance,
    "chart-box": mdiChartBox,
    "chevron-right": mdiChevronRight,
    "account-arrow-right": mdiAccountArrowRight,
    "apps": mdiApps,
    "signal-cellular-1": mdiSignalCellular1,
    "signal-cellular-2": mdiSignalCellular2,
    "signal-cellular-3": mdiSignalCellular3,
    "signal-cellular-off": mdiSignalOff,
}

class MaterialCommunityIcon extends Component<Props, State> {


    render() {
        const {name, size, color, style, className, circled} = this.props;

        let path = MDIMap[name];
        if (!path) {
            console.warn('MDI Icon not fount', name);
            path = mdiHeadQuestionOutline;
        }

        let numberSize = size || "1.2em";
        if (!_.isString(numberSize)) {
            numberSize = `${numberSize}px`
        }


        const circleStyles = {backgroundColor: color || 'auto'};
        return <i className={`mdi-icon ${circled ? 'circled' : ''} ${className ? className : ''}`} style={circled ? circleStyles : null}>
            <Icon path={path} size={numberSize} style={style} color={circled ? 'white' : color}/>
        </i>;
    }
}

export default MaterialCommunityIcon;
